import React from "react"
import { Link } from "gatsby"
export default function () {
  const footerMenu = [
    {
      title: "About",
      items: [
        {
          name: "Academics",
          path: "/academics",
        },
        {
          name: "Our Values",
          path: "/about",
        },
        {
          name: "Admission",
          path: "/admission",
        },
        {
          name: "Scholarship",
          path: "/scholarship",
        },
        {
          name: "Facilities",
          path: "/facilities",
        },
      ],
    },
    {
      title: "Essentials",
      items: [
        {
          name: "Calendar",
          path: "/calendar",
        },
        {
          name: "Events",
          path: "/events",
        },
        {
          name: "School Bus Route (Lalitpur)",
          path:
            "https://www.google.com/maps/d/edit?mid=1c3WsDgtHqKTsDgXSqP1-yaBMHB5VCgTU&usp=sharing",
          outLink: true,
          targetBlank: true,
        },
        // {
        //   name: "School Bus Route (Itahari)",
        //   path: "",
        //   outLink: true,
        // },
        {
          name: "Contact",
          path: "/contact",
        },
      ],
    },
    {
      title: "Beyond School",
      items: [
        {
          name: "Bloom Nepal Foundation",
          path: "https://bloomnf.org",
          outLink: true,
          targetBlank: true,
        },
        {
          name: "BloomED",
          path: "http://bloomedn.org",
          outLink: true,
          targetBlank: true,
        },
      ],
    },
  ]
  return (
    <section className="bg-white mt-16 md:mt-32 border-t border-gray-200">
      <div className="grid grid-cols-3 gap-x-2 md:gap-x-12">
        {footerMenu.map((menuCard, index) => {
          return (
            <div
              key={`${menuCard.title}`}
              className={`md:border-transparent self-start`}
            >
              <h4 className="uppercase font-semibold text-blue-700 text-base md:text-lg text-left sm:text-center leading-tight">
                {menuCard.title}
              </h4>
              <ul className="mt-2 ">
                {menuCard.items.map(menu => (
                  <li
                    className="text-left mt-3 sm:mt-4 sm:text-center leading-none"
                    key={menu.path + menu.name}
                  >
                    {menu.outLink ? (
                      <a
                        href={`${menu.path}`}
                        target={menu.targetBlank ? "_blank" : ""}
                        className="button-outline text-sm md:text-base leading-none text-black hover:text-gray-800 hover:underline text-left sm:text-center"
                      >
                        {" "}
                        {menu.name}
                      </a>
                    ) : (
                      <Link
                        to={`${menu.path}`}
                        className="button-outline leading-none md:text-base text-black text-sm hover:text-gray-800 hover:underline text-left sm:text-center"
                      >
                        {menu.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )
        })}
      </div>
      <div className="section-for-small-devices text-center text-sm text-gray-800 mt-8">
        <p>
          &copy; {new Date().getFullYear()} |{" "}
          <span className="text-red-800 font-medium">Bloom Nepal School</span>
        </p>
        <div className="my-6">
          <Link to="/privacy" className="in-link border-b ">
            Privacy Policy
          </Link>{" "}
          <span> | </span>
          <Link to="/rules" className="in-link border-b ">
            Rules and Regulations
          </Link>{" "}
        </div>
      </div>
      <div className="section-for-small-devices text-center text-sm text-gray-900 my-6">
        <p>
          <a
            href="https://www.facebook.com/bloomnepal"
            className="in-link border-b"
          >
            Facebook
          </a>{" "}
          |
          <a
            href="https://www.youtube.com/channel/UC9VQZppX9zCQt5EajiGKPmg"
            className="in-link border-b"
          >
            Youtube
          </a>{" "}
        </p>
      </div>
    </section>
  )
}
