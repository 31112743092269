import { schools } from "../data/schools.json"

export const allSchoolLocations = function () {
  return schools.map(school => school["location"])
}


export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export const isInViewPort = function (elem) {
  if (elem) {
    const bounding = elem.getBoundingClientRect()
    // console.log(bounding)
    // return (
    //   bounding.top <= 0 &&
    //   bounding.bottom >=
    //     (window.innerHeight || document.documentElement.clientHeight)
    // )
    return bounding.top <= 0 && bounding.bottom > window.innerHeight / 2
  }
}

export default function (allJobs) {
  let jobs = []
  allJobs.forEach(node => {
    if (new Date(node.deadline) >= todayDate()) {
      jobs.push(node)
    }
  })
  return jobs
}

export const todayAndNextYearDate = function () {
  const today = new Date()
  let year, month, day, nextYear
  year = today.getFullYear()
  month = today.getMonth() + 1
  day = today.getDate()
  nextYear = year + 1
  if (month < 9) {
    month = "0" + month
  } else {
    month += ""
  }
  year += ""
  if (day < 9) {
    day = "0" + day
  } else {
    day += ""
  }
  nextYear += ""

  return [`${year}-${month}-${day}`, `${nextYear}-${month}-${day}`]
}

export const todayDate = function () {
  // Returns today's date in "new Date(2020-10-20)" format
  const today = todayAndNextYearDate()[0]
  return new Date(today)
}

export const todayDateRaw = function () {
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth()
  let monthWord
  switch (month) {
    case 0:
      monthWord = "Jan"
      break
    case 1:
      monthWord = "Feb"
      break
    case 2:
      monthWord = "Mar"
      break
    case 3:
      monthWord = "Apr"
      break
    case 4:
      monthWord = "May"
      break
    case 5:
      monthWord = "Jun"
      break
    case 6:
      monthWord = "Jul"
      break
    case 7:
      monthWord = "Aug"
      break
    case 8:
      monthWord = "Sep"
      break
    case 9:
      monthWord = "Oct"
      break
    case 10:
      monthWord = "Nov"
      break
    case 11:
      monthWord = "Dec"
      break
    default:
      break
  }

  const date = today.getDate()

  return `${monthWord}-${date}, ${year}`
}
