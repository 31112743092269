import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import jobsPreviousToDeadline from "../utils/helpers";
import { debounce } from "../utils/helpers";
// import { GatsbyImage } from "gatsby-plugin-image";

export const menus = [
  {
    type: "group",
    text: "About US",
    items: [
      { type: "singular", text: "Our values", linkTo: "/about" },
      { type: "singular", text: "Facilities", linkTo: "/facilities" },
      { type: "singular", text: "Team", linkTo: "/team" },
      { type: "singular", text: "Contact", linkTo: "/contact" },
    ],
  },
  {
    type: "group",
    text: "Academics and Events",
    items: [
      { type: "singular", text: "Academics", linkTo: "/academics" },
      { type: "singular", text: "Calendar", linkTo: "/calendar" },
      { type: "singular", text: "Club Events", linkTo: "/events" },
    ],
  },
  { type: "singular", text: "Stories", linkTo: "/stories" },
  {
    type: "group",
    text: "Admission",
    items: [
      { type: "singular", text: "How To Apply", linkTo: "/admission" },
      { type: "singular", text: "Scholarship", linkTo: "/scholarship" },
      { type: "singular", text: "Fees", linkTo: "/fees" },
    ],
  },
];

const MenuGroupForSmallDevices = ({ menus, setShowMenu }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className=" py-4 md:py-6 my-2 md:my-4">
      <button
        className="button-outline"
        onClick={() => {
          setShowMore((currentValue) => !currentValue);
        }}
      >
        <div className="button-outline border-b flex justify-center cursor-pointer select-none">
          <h4 className="font-medium mx-1">{menus.text} </h4>
          <span className="block mx-1">
            {showMore ? <span>&uarr;</span> : <span>&darr;</span>}
          </span>
        </div>
      </button>

      {showMore ? (
        <ul>
          {menus.items.map((item) => (
            <li className="select-none">
              <Link
                to={item.linkTo}
                className="button-outline select-none block py-2 my-2"
                onClick={() => {
                  setShowMenu(false);
                }}
              >
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};

const MenuForSmallDevices = ({ menus, setMenuOpen }) => {
  return (
    <nav className="lg:hidden text-xl px-2 md:px-4 lg:px-8  w-11/12 mx-auto text-center">
      {menus.map((menu) => {
        if (menu.type === "singular") {
          return (
            <Link
              to={menu.linkTo}
              key={menu.linkTo}
              className="py-4 md:py-6 block font-medium my-2 md:my-4 button-outline"
              onClick={() => {
                setMenuOpen(false);
              }}
            >
              {menu.text}
            </Link>
          );
        } else if (menu.type === "group") {
          return (
            <MenuGroupForSmallDevices
              menus={menu}
              key={menu.linkTo}
              setShowMenu={setMenuOpen}
            />
          );
        } else {
          return null;
        }
      })}
      <Link
        to="/contact"
        className="button-outline py-4 md:py-6 block font-medium my-2 md:my-4"
        onClick={() => {
          setMenuOpen(false);
        }}
      >
        Contact
      </Link>
      <Link
        to="/careers"
        className="button-outline py-4 md:py-6 block font-medium my-2 md:my-4"
        onClick={() => {
          setMenuOpen(false);
        }}
      >
        Careers
      </Link>
      <a
        href="https://bloomnf.org/gift-education"
        className="button-outline px-4 sm:px-8 md:px-16 py-2 md:py-4 inline-block font-medium my-2 bg-red-700 text-white"
      >
        Donate
      </a>
    </nav>
  );
};

const MenuGroup = ({ menuItem, indexValue, isHiring }) => {
  // Don't display the contact under about if not hiring
  const menuDropDown = useRef(null);
  return (
    <button
      className={`inline-block relative button-outline`}
      key={menuItem.text}
      style={{ zIndex: (5 - indexValue) * 10 }}
      onClick={() => {
        menuDropDown.current.style = "visibility: visible;";
      }}
      onMouseEnter={() => {
        menuDropDown.current.style = "visibility: visible;";
      }}
      onMouseLeave={() => {
        menuDropDown.current.style = "visibility: hidden;";
      }}
    >
      <span className=" flex items-center font-medium px-1 mr-4 my-2 cursor-pointer menu-dropdown">
        {menuItem.text}
        <i className="arrow down ml-2"></i>
      </span>
      <ul className="py-4 invisible absolute bg-gray-300" ref={menuDropDown}>
        {menuItem.items.map((item) => {
          if (item.linkTo === "/contact" && !isHiring) {
            return (
              <Link
                key="/careers"
                to="/careers"
                className="button-outline capitalize font-medium block my-2 py-2 px-8 hover:bg-blue-700 hover:text-white"
              >
                <li className="leading-tight">Careers</li>
              </Link>
            );
          } else {
            return (
              <Link
                to={item.linkTo}
                key={item.linkTo}
                className="button-outline capitalize font-medium block my-2 py-2 px-8 hover:bg-blue-700 hover:text-white"
              >
                <li className="leading-tight">{item.text}</li>
              </Link>
            );
          }
        })}
      </ul>
    </button>
  );
};

const Menu = ({ isHiring }) => {
  return (
    <ul className="ml-16">
      {menus.map((menuItem, index) => {
        if (menuItem.type === "singular") {
          return (
            <Link
              to={menuItem.linkTo}
              key={menuItem.linkTo}
              className="button-outline px-3 py-2 mr-4 my-2 hover:bg-blue-700 hover:text-white rounded"
            >
              <li className="inline-block capitalize font-medium">
                {menuItem.text}
              </li>
            </Link>
          );
        } else if (menuItem.type === "group") {
          return (
            <MenuGroup
              menuItem={menuItem}
              indexValue={index}
              isHiring={isHiring}
              key={menuItem.text}
            />
          );
        } else {
          return null;
        }
      })}

      {isHiring ? (
        <Link
          className="inline-block my-2 
        hover:bg-blue-700 rounded hover:text-white px-3 mr-4"
          to="/careers"
          // activeStyle={{
          //   borderBottom: "2px solid red ",
          // }}
        >
          <li className="relative py-2 ">
            Careers
            <div
              className="absolute top-0 bg-green-600 text-white px-1 rounded"
              style={{ fontSize: "0.5rem" }}
            >
              {isHiring ? "We're Hiring" : ""}
            </div>
          </li>
        </Link>
      ) : (
        <Link
          className="inline-block my-2  button-outline
        hover:bg-blue-700 rounded hover:text-white px-3 mr-4"
          to="/contact"
          // activeStyle={{
          //   borderBottom: "2px solid red ",
          // }}
        >
          <li className="relative py-2 ">
            <div className="capitalize font-medium">Contact</div>
          </li>
        </Link>
      )}

      <a
        className=" button-outline inline-block my-2 bg-red-700 text-white rounded
        hover:bg-red-800 hover:text-white px-3"
        href="https://bloomnf.org/gift-education"
        target="_blank"
        rel="noreferrer"
      >
        <li className="relative py-2">
          <div className="capitalize font-medium">Donate</div>
        </li>
      </a>
    </ul>
  );
};

export default function ({ setChildVisible, className }) {
  const data = useStaticQuery(graphql`
    query {
      logoSmall: file(relativePath: { eq: "logo-bloom.png" }) {
        childImageSharp {
          fixed(height: 75) {
            aspectRatio
            base64
            src
            srcSet
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      allSanityCareerPosition {
        nodes {
          deadline
        }
      }
    }
  `);

  const [menuOpen, setMenuOpen] = useState(false);
  const jobs = jobsPreviousToDeadline(data.allSanityCareerPosition.nodes);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const isHiring = jobs.length > 0;

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 10
    );

    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  useEffect(() => {
    if (menuOpen) {
      setChildVisible(true);
    } else {
      setChildVisible(true);
    }
  }, [menuOpen, setChildVisible]);

  return (
    <nav
      className={` lg:bg-gray-100 left-auto lg:sticky relative z-10 ${className} ${
        visible ? "top-0" : ""
      }`}
      id="header"
      style={{
        top: visible ? "0px" : "",
      }}
    >
      <div className="flex justify-between px-8 py-4 items-center">
        <Link to="/">
          <h1 className="text-2xl text-red-900 hover:text-red-800 leading-tight no-select font-medium">
            <Img
              fixed={data.logoSmall.childImageSharp.fixed}
              draggable={false}
            />
          </h1>
        </Link>
        <div>
          <div className="hidden lg:block">
            <Menu isHiring={isHiring} />
          </div>
          <div className="block lg:hidden">
            {menuOpen ? (
              <button
                className="block button-outline fill-current border hover:border-transparent border-gray-800 hover:bg-red-800 hover:text-white font-medium  uppercase px-4 py-2 "
                onClick={() => {
                  setMenuOpen((current) => !current);
                }}
                onKeyDown={() => {
                  setMenuOpen((current) => !current);
                }}
              >
                X
              </button>
            ) : (
              <button
                className="block button-outline fill-current border hover:bg-blue-800 hover:text-white hover:border-transparent border-black font-medium  uppercase px-4 py-2 "
                onClick={() => {
                  setMenuOpen((current) => !current);
                }}
                onKeyDown={() => {
                  setMenuOpen((current) => !current);
                }}
              >
                Menu
              </button>
            )}
          </div>
        </div>
      </div>
      {/* Menu list when the menu is open */}
      {menuOpen ? (
        <ul className="overflow-y-scroll pb-16 lg:pb-0">
          <MenuForSmallDevices menus={menus} setMenuOpen={setMenuOpen} />
        </ul>
      ) : (
        ""
      )}
    </nav>
  );
}
