import React, { useState, useRef, useEffect } from "react"
import "../styles/index.css"
import "../styles/homepage.css"
import Header from "./header"
import Footer from "./footer"

import "@fontsource/raleway/400.css"
import "@fontsource/raleway/500.css"
import "@fontsource/raleway/600.css"
import "@fontsource/raleway/700.css"
import "@fontsource/raleway/800.css"
import "@fontsource/raleway/900.css"

export default function ({ children, className }) {
  const [childVisible, setChildVisible] = useState(true)
  const childrenContent = useRef()
  const documentContent = useRef()

  useEffect(() => {
    //make the footer stick at the bottom by dynamically upddating minHeight of the children
    if (window.innerHeight > documentContent.current.offsetHeight) {
      const newChildrenHeight = `${
        window.innerHeight -
        documentContent.current.offsetHeight +
        childrenContent.current.offsetHeight
      }px`
      childrenContent.current.style.minHeight = newChildrenHeight
    }
  }, [])

  return (
    <div className="font-main mx-auto max-w-screen-xl  text-lg" ref={documentContent}>
      <Header setChildVisible={setChildVisible} className="xl:px-12 lg:px-8 md:px-12" />
      <div
        className={`${
          !childVisible ? "opacity-25" : "opacity-100"
        } max-w-screen-xl lg:px-12`}
      >
        <main className={`${className} md:px-12 `} ref={childrenContent}>
          {children}
        </main>
        <Footer />
      </div>
    </div>
  )
}
